import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import * as Styled from './richTextWithGreyCardRightStyles';
import { useContentfulAssets } from '../../../hooks/useContentfulAssets';
import AOS from 'aos';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
} from '../../../utils';

const RichTextWithGreyCardRight = ({ sectionData }) => {
  React.useLayoutEffect(() => {
    AOS.init({
      disable: function () {
        const maxWidth = 767;
        return window.innerWidth < maxWidth;
      },
    });
    AOS.refresh();
  }, []);
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HeaderH2>{children}</Styled.HeaderH2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.HeaderH3>{children}</Styled.HeaderH3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Styled.HeadingH4>{children}</Styled.HeadingH4>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Styled.HeaderH6>{children}</Styled.HeaderH6>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <Styled.UnorderedList>{children}</Styled.UnorderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <Styled.ListItem>{children}</Styled.ListItem>
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <Styled.BlockQuotes
          data-aos="fade-up"
          data-aos-delay="400"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          {children}
        </Styled.BlockQuotes>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.BlockQuotesLinkStyle
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(
                  process.env.GATSBY_DOMAIN_URL + node?.data?.uri
                )
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : ''}
        >
          {children}
        </Styled.BlockQuotesLinkStyle>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node.data.target.sys.id);
        if (asset) {
          return (
            <Styled.ImageStyle
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1000"
              data-aos-once="true"
              src={asset.node.file.url}
              alt={asset.node.title}
            />
          );
        }
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <>
      {sectionData?.header?.trim() && (
        <Styled.HeaderH2 className="lessMargin">
          {sectionData?.header}
        </Styled.HeaderH2>
      )}

      {sectionData?.contentDetails &&
        documentToReactComponents(
          JSON.parse(sectionData?.contentDetails?.raw),
          optionsMainStyle
        )}
    </>
  );
};
export default RichTextWithGreyCardRight;
